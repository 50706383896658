body, h5, h6,
.badge, .note, .grav-mdeditor-preview,
input, select, textarea, button, .selectize-input,
h1, h2, h3, h4,
#admin-menu li, .form-tabs > label, .label,
body .CodeMirror pre {
    font-family: "Helvetica Neue", "Helvetica", "Tahoma", "Geneva", "Arial", sans-serif;
}
code, kbd, pre, samp, .mono  {
    font-family: "Monaco", "Consolas", "Lucida Console", monospace;
}
